<script setup lang="ts">
import {
  AccessControl,
  EditorHeader,
  EditorSide,
  FilePreviewUnsupportedToast,
  FileUploadModal,
  ProjectCompileResult,
} from '@/components'
import AskAI from '@/components/AskAI.vue'
import CommentFloating from '@/components/CommentFloating.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import { ASK_AI_BUTTON_DELAY_MS, COLLABORATIVE_EDITS_WS_URL } from '@/config'
import { useCommentStore, useEditorStore, useErrorStore, useUserStore } from '@/stores'
import { useBibFileStore } from '@/stores/bibFile'
import { useHelpStore } from '@/stores/help'
import { usePermission } from '@/stores/permission'
import { useEditorStyle } from '@/views/EditorViewEditorStyle'
import NoFileSelected from '@/views/EditorViewNoFileSelected.vue'
import { usePopupElementPosition } from '@/views/EditorViewPopupElementPosition'
import { EditorState, SelectionRange, StateEffect, Text } from '@codemirror/state'
import { EditorView, type ViewUpdate, keymap } from '@codemirror/view'
import { Permission } from '@murfy-package/api-client'
import { type EditorPayload, MurfyEditor, useEditor } from '@murfy-package/editor'
import { getExtensionsByLanguage } from '@murfy-package/editor/src/config'
import { bibKeywords } from '@murfy-package/editor/src/extensions/autocomplete/latexKeywords'
import { collaborator } from '@murfy-package/editor/src/extensions/collaborator'
import { addCommentClickHandler } from '@murfy-package/editor/src/extensions/commentMark'
import type { Range } from '@murfy-package/editor/src/extensions/formula-turbo/formulaTurbo'
import type { Annotation } from '@murfy-package/editor/src/extensions/languages/latex/linter/helper/annotation'
import { setAnnotations } from '@murfy-package/editor/src/extensions/languages/latex/linter/helper/annotations'
import { useMathPatterns } from '@murfy-package/editor/src/hooks/useMathPatterns'
import type { Stores } from '@murfy-package/editor/src/stores/stores'
import type { LogEntry } from '@murfy-package/latex-log-parser'
import {
  ActivityBar,
  IconBase,
  IconCircleCheck,
  IconCommentLine,
  IconFileEmpty,
  IconFunction,
  IconShareNodes,
} from '@murfy-package/murds'
import { BaseProgressSpinner } from '@murfy-package/ui'
import { storeToRefs } from 'pinia'
import {
  type Ref,
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  shallowRef,
  toRaw,
  watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { LoadingView } from '.'
import { Activity, useActivity } from './EditorViewActivity'

const editorStore = useEditorStore()
const { parsedCompileLog } = storeToRefs(editorStore)
const { fetchProject } = editorStore
const route = useRoute()
const router = useRouter()
// FIXME: 로딩 부분은 route 훅에서 store를 호출해서 처리하는게 좋음
// - 레이아웃 코드가 간결해 짐
// - isLoading 도 스토어에 옮길 수 있음
const isPageLoading = ref(false)
const isAccessDenied = ref(false)
const projectId = ref<string | null>(null)
const { parseBibFiles } = useBibFileStore()
const getReferences = () => {
  projectId.value &&
    parseBibFiles(projectId.value).then((bibData) => {
      bibKeywords.length = 0
      bibKeywords.push(...bibData)
    })
}
watch(
  () => route.params.id,
  (id) => {
    projectId.value = Array.isArray(id) ? id[0] : id
    editorStore.$reset()
    isPageLoading.value = true
    isAccessDenied.value = false
    fetchProject(projectId.value)
      .catch((error) => {
        if (error.statusCode === 403) {
          isAccessDenied.value = true
        } else {
          setError(error)
          router.push('/dashboard')
        }
      })
      .finally(() => {
        isPageLoading.value = false
      })
    getReferences()
  },
  {
    immediate: true,
  },
)

const {
  currentFilePath,
  language,
  layout,
  mode,
  isModifiedAfterSave,
  isModifiedAfterRender,
  isSaving,
  isLoading,
  autoSaveTimer,
} = storeToRefs(editorStore)
const { save, renderPdf, setEditorView } = editorStore

const { showResult, loaderStyle, editorWidthStyle, previewGutterRef, sidebarGutterRef } =
  useEditorStyle(mode, layout, isLoading)

const { setError } = useErrorStore()
const { useCursor, useSyntaxChecker } = useEditor()

/**
 * 에디터의 view 객체
 * 에디터가 준비되면 handleReady 함수에서 할당됨
 * FIXME:
 * shallowRef라 에디터의 상태가 변경되어도 변경 감지가 되지 않음
 * ref로 하면 변경 감지가 되지만, useCursor에서 view 객체를 사용할 때 에러가 발생함
 */
const view = shallowRef<EditorView>()

// 에디터가 준비되면 오는 delegate 함수
const cursorHeight = ref(0)
const handleReady = (payload: EditorPayload) => {
  setEditorView(payload.view)
  view.value = payload.view
  view.value.dispatch({
    effects: StateEffect.appendConfig.of(
      keymap.of([
        {
          key: 'Mod-s',
          run: () => {
            if (autoSaveTimer.value) {
              clearTimeout(autoSaveTimer.value)
            }
            autoSaveCallback().then(renderPdf)
            return true
          },
        },
        {
          key: 'Space',
          run: () => {
            if (!view.value || isCursorInMathEnvironment()) return false

            const cursorPos: number = view.value.state.selection.main.head
            const line = view.value.state.doc.lineAt(cursorPos)
            const text = view.value.state.doc.sliceString(line.from, line.to)

            // 라인이 비어 있는 경우에만 Ask AI 실행
            if (text === '') {
              updateShouldShowAI()
              return true
            }
            return false
          },
        },
      ]),
    ),
  })
  cursorHeight.value = useCursor(view.value).heightInPixel
}
// Comment 관련
const commentStore = useCommentStore()
const {
  fetchComments,
  openComment,
  saveAllCommentLocations,
  updateBoundingRect: updateCommentRect,
} = commentStore
const { selectedComment, selectedCommentTop, selectedCommentLeft } = storeToRefs(commentStore)
const viewCommentTop = computed(() => selectedCommentTop.value + cursorHeight.value)
const viewCommentLeft = computed(() => selectedCommentLeft.value)
// Syntax Checking
const { syntaxChecking, checkState } = useSyntaxChecker(view)

// 에디터 내부에서 상태 변경 되면 오는 함수
const change = (_event: string, _payload: string) => {
  if (!isEditorReady.value) {
    return
  }
  isModifiedAfterSave.value = true
  isModifiedAfterRender.value = true
  if (autoSaveTimer.value) {
    clearTimeout(autoSaveTimer.value)
  }
  autoSaveTimer.value = setTimeout(autoSaveCallback, 1000)
}

/**
 * 커서 위치와 매칭 범위를 확인하는 공통 함수
 */
const isCursorInMathEnvironment = () => {
  if (!view.value) {
    return false
  }
  const cursorPos = useCursor(view.value).cursorPos
  const { ranges } = useMathPatterns(view.value.state.doc)
  const matchingRanges = ranges.value.filter(
    (range: Range) => cursorPos > range.from && cursorPos < range.to - range.offset,
  )
  return matchingRanges.length > 0
}

/**
 * Ask AI 버튼을 보여야 하는지 여부
 */
const shouldShowAI = ref(false)
let shouldShowAITimer: NodeJS.Timeout | null = null
/**
 * Ask AI 버튼을 보여야 하는지 계산. view가 업데이트 될 때마다 호출
 * - view가 없으면 보여주지 않음
 * - language가 latex가 아니면 보여주지 않음
 * - 커서가 비어있고, 커서가 있는 라인이 비어있을 경우, Tab을 입력하면 보여줌
 * - 커서가 비어있지 않으면 ( 선택된 텍스트가 있으면 ) 보여줌
 */
const updateShouldShowAI = () => {
  if (!view.value || isCursorInMathEnvironment()) {
    shouldShowAI.value = false
    return
  }

  const emptyCursor = useCursor(view.value).isEmpty
  const cursorLine = useCursor(view.value).line
  const emptyCursorInEmptyLine = emptyCursor && cursorLine.text === ''
  const newShouldShowAI = language.value === 'latex' && (!emptyCursor || emptyCursorInEmptyLine)
  if (newShouldShowAI && newShouldShowAI !== shouldShowAI.value) {
    // AI 버튼이 보여져야 하는 경우, ASK_AI_BUTTON_DELAY_MS 후에 보여줌
    // 텍스트 드래그 시 매 드래그마다 랜더링 되는 것을 방지하기 위함.
    if (shouldShowAITimer) {
      clearTimeout(shouldShowAITimer)
    }
    shouldShowAITimer = setTimeout(() => {
      shouldShowAI.value = true
    }, ASK_AI_BUTTON_DELAY_MS)
    shouldShowAI.value = false
    return
  }
  if (!newShouldShowAI && shouldShowAITimer) {
    clearTimeout(shouldShowAITimer)
  }
  shouldShowAI.value = newShouldShowAI
  return
}

// Ask AI 버튼 위치 계산
const editorArea = ref<HTMLElement | null>(null)
const askAIRef = ref<InstanceType<typeof AskAI> | null>(null)
const askAIElement = computed(() => askAIRef.value?.$el)
const { popupElementPositionStyle, updateCursorRect } = usePopupElementPosition(
  view,
  editorArea,
  askAIElement,
)

/**
 * view.state.selection.main을 반응형 상태로 변경하기 위해 별도로 선언.
 * view 가 ref로 변경될 수 있다면 필요 없음.
 */
const selection: Ref<SelectionRange | null> = ref(null)

const docRef: Ref<Text | null> = ref(null)

const update = (viewUpdate: ViewUpdate) => {
  selection.value = viewUpdate.state.selection.main
  view.value = viewUpdate.view
  docRef.value = viewUpdate.state.doc
  updateCursorRect()
  updateCommentRect()
  if (viewUpdate.selectionSet) {
    shouldShowAI.value = false

    // 선택된 텍스트가 있는 경우 Ask Ai 가 보이도록 합니다.
    const selectedText = viewUpdate.state.doc
      .sliceString(selection.value.from, selection.value.to)
      .trim()
    if (selectedText.length > 0) {
      updateShouldShowAI()
    }
  }

  //FIXME: 변경내용이 제대로 전파되면 지울 것
  checkState(viewUpdate.state.doc)
}

const autoSaveCallback = () => {
  if (!isEditorReady.value) {
    isModifiedAfterSave.value = false
    isSaving.value = false
    return
  }

  isSaving.value = true
  // bib 파일을 저장 하는 경우 새로운 Reference 가져오기
  language.value === 'bibtex' && getReferences()
  saveAllCommentLocations()
  return save()
    .then(() => {
      isModifiedAfterSave.value = false
    })
    .catch((e) => {
      setError(e)
    })
    .finally(() => {
      isSaving.value = false
    })
}

// 에디터에 focus 되면 오는 함수
const focus = (_event: string, _payload: ViewUpdate) => {}

// 에디터에 blur 되면 오는 함수
const blur = (_event: string, _payload: ViewUpdate) => {}
const { fetchRolePermissions } = usePermission()
onMounted(() => {
  window.addEventListener('resize', updateCursorRect)
  window.addEventListener('resize', updateCommentRect)
  window.addEventListener('beforeunload', handleBeforeUnload)
  fetchRolePermissions()
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', updateCursorRect)
  window.removeEventListener('resize', updateCommentRect)
  window.removeEventListener('beforeunload', handleBeforeUnload)
  handleBeforeUnload()
})

const handleBeforeUnload = () => {
  if (collaborationExtension.value) {
    collaborationExtension.value.close()
    collaborationExtension.value = null
  }
}

const { t } = useI18n()

watch([currentFilePath, parsedCompileLog, docRef], ([newFilePath, parsedLog, doc]) => {
  if (!view.value || !newFilePath || doc === view.value.state.doc) {
    return
  }

  const annotations: Annotation[] = parsedLog
    .filter((logEntry: LogEntry) => logEntry.fileName === newFilePath)
    .map((logEntry: LogEntry) => ({
      row: logEntry.lineNumber ? logEntry.lineNumber - 1 : 0,
      type:
        logEntry.level === 'error' ? 'error' : logEntry.level === 'warning' ? 'warning' : 'info',
      text: logEntry.message,
    }))

  setTimeout(() => {
    view.value && doc && view.value.dispatch(setAnnotations(doc, annotations || []))
  }, 100)
})

const userName = computed(() => useUserStore().me?.name ?? '')

const collaborationExtension = ref()
watch(
  currentFilePath,
  async (currentValue, oldValue) => {
    if (oldValue === currentValue) {
      return
    }
    // 이미 연결이 있는 상태라면 연결을 끊음
    if (collaborationExtension.value) {
      collaborationExtension.value.close()
      collaborationExtension.value = null
    }
    // 파일이 선택되지 않았다면 연결을 하지 않음
    if (!currentValue) {
      return
    }
    // 현재 파일로 협업 세션을 생성
    const { token, fileKey } = await editorStore.apiClient.collaborate.issueCollaborateRoomToken(
      //FIXME: projectId가 null이면 에러가 발생함, 현재는 projectId가 null이 되는 경우가 없음
      projectId.value || '',
      currentValue,
    )
    collaborationExtension.value = collaborator(
      COLLABORATIVE_EDITS_WS_URL,
      fileKey,
      userName.value,
      token,
    )
  },
  {
    immediate: true,
  },
)

const { checkPermission } = usePermission()
const isEditorReady = computed(
  () => !isPageLoading.value && !isLoading.value && collaborationExtension.value,
)
watch(isEditorReady, (ready) => {
  if (ready && checkPermission(Permission.fileUpdate)) {
    nextTick(() => {
      fetchComments()
    })
  }
})
const isNotSelected = computed(
  () => !(currentFilePath.value || isLoading.value || isPageLoading.value),
)
const editorExtensions = computed(() => {
  if (!isEditorReady.value) {
    return []
  }
  const languageExtensions = [getExtensionsByLanguage[language.value ?? 'default']]
  const editableExtension = EditorView.editable.of(checkPermission(Permission.fileUpdate))
  const readonlyExtension = EditorState.readOnly.of(!checkPermission(Permission.fileUpdate))
  const commentExtension = addCommentClickHandler(openComment)
  return [
    toRaw(collaborationExtension.value?.extension),
    commentExtension,
    editableExtension,
    readonlyExtension,
    ...languageExtensions,
  ]
})

const beforeActiveActivity = ref<Activity | undefined>(undefined)
const { activities, activeActivity } = useActivity()
watch(activeActivity, (active) => {
  if (active !== Activity.FormulaTurbo) beforeActiveActivity.value = active
})

const helpStore = useHelpStore()
const { helpVisible } = storeToRefs(helpStore)
watch(helpVisible, (visible) => {
  if (visible) {
    activeActivity.value = Activity.FormulaTurbo
  } else {
    activeActivity.value = beforeActiveActivity.value
  }
})

const stores: Stores = {
  helpStore,
}
</script>

<template>
  <LoadingView v-if="isPageLoading" :message="t('loadingProject')" />
  <main
    v-if="!isAccessDenied"
    class="grid-rows-editor bg-gray-0 m-0 grid h-full w-full grid-cols-12 overflow-hidden overflow-x-auto p-0"
    :style="editorWidthStyle"
  >
    <EditorHeader class="col-end-last z-40 col-start-1 row-start-1 row-end-1 h-16" />
    <div
      v-if="!isEditorReady && !isNotSelected"
      class="col-end-last row-end-last bg-gray-white-n-black z-30 col-start-[--loader-start-col] row-start-[--loader-start-row] flex flex-col items-center justify-center"
      :style="loaderStyle"
    >
      <BaseProgressSpinner />
      <p>{{ t('loading') }}</p>
    </div>
    <div
      class="row-end-last border-color-border-primary bg-color-bg-global-primary z-50 col-start-1 col-end-2 row-start-2 border-r"
    >
      <ActivityBar v-model="activeActivity" :activities="activities" orientation="vertical">
        <template #activity="{ activity }">
          <IconBase :width="24" :height="24">
            <IconFileEmpty v-if="activity === Activity.File" />
            <IconShareNodes v-else-if="activity === Activity.Share" />
            <IconCommentLine v-else-if="activity === Activity.Comment" />
            <IconFunction v-else-if="activity === Activity.FormulaTurbo" />
          </IconBase>
        </template>
      </ActivityBar>
    </div>
    <EditorSide
      :activeActivity="activeActivity"
      class="row-end-last z-50 col-start-2 col-end-3 row-start-2 overflow-visible"
    />
    <div
      ref="sidebarGutterRef"
      class="hover:bg-cta-pressed active:bg-cta-pressed row-end-last z-10 col-start-3 col-end-4 row-start-2 cursor-ew-resize bg-gray-300 transition-all duration-300"
      @mousedown.prevent
    />
    <div
      class="bg-gray-white-n-black z-10 col-start-[--editor-editor-start-col] col-end-[--editor-editor-end-col] row-span-1 row-start-2 flex flex-row items-center justify-between border-b border-solid border-gray-300 px-6 py-3"
    >
      <div class="head-xs text-color-text-primary flex items-center">{{ currentFilePath }}</div>
      <AccessControl :permissions="[Permission.fileUpdate]">
        <div class="flex items-center">
          <ToggleButton
            v-if="currentFilePath && language === 'latex'"
            v-model="syntaxChecking"
            textOnly
          >
            <span class="body-sm flex items-center gap-2">
              <IconBase iconName="check">
                <IconCircleCheck />
              </IconBase>
              {{ t('editor.inspections') }}
            </span>
          </ToggleButton>
        </div>
      </AccessControl>
    </div>

    <div
      ref="editorArea"
      class="row-end-last relative z-0 col-start-[--editor-editor-start-col] col-end-[--editor-editor-end-col] row-start-3 overflow-auto"
    >
      <template v-if="isEditorReady">
        <div class="f-full grid h-[--editor-height] grid-cols-12">
          <div class="bg-gray-white-n-black col-span-12 overflow-y-auto">
            <MurfyEditor
              class="f-full w-full"
              :stores="stores"
              :extensions="editorExtensions"
              @ready="handleReady"
              @change="change('change', $event)"
              @focus="focus('focus', $event)"
              @blur="blur('blur', $event)"
              @update="update($event)"
              @scroll="
                () => {
                  updateCursorRect()
                  updateCommentRect()
                }
              "
            />
            <AccessControl :permissions="[Permission.fileUpdate]">
              <CommentFloating
                v-if="selectedComment"
                :top="viewCommentTop"
                :left="viewCommentLeft"
                :commentId="selectedComment.id"
                @close="selectedComment = null"
              />
              <AskAI
                v-if="view"
                ref="askAIRef"
                class="absolute z-50"
                :style="popupElementPositionStyle"
                :visible="shouldShowAI"
                :view="view"
                :selection="selection"
              />
            </AccessControl>
          </div>
        </div>
      </template>
      <NoFileSelected v-else-if="isNotSelected" />
    </div>
    <div
      v-if="showResult && mode !== 'preview'"
      ref="previewGutterRef"
      class="hover:bg-cta-pressed active:bg-cta-pressed row-end-last z-20 col-start-5 col-end-6 row-start-2 cursor-ew-resize bg-gray-300 transition-all duration-300"
      @mousedown.prevent
    />
    <ProjectCompileResult
      v-if="showResult"
      class="row-end-last z-10 col-start-[--editor-result-start-col] col-end-[--editor-result-end-col] row-start-2 overflow-hidden"
    />
  </main>
  <FilePreviewUnsupportedToast />
  <FileUploadModal />
  <ConfirmModal
    :visible="isAccessDenied"
    :header="t('accessDeniedModal.header')"
    :content="t('accessDeniedModal.content')"
    :onConfirm="() => $router.push('/dashboard')"
    :confirmLabel="t('accessDeniedModal.goToDashboard')"
  />
</template>

<style module>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
</style>

<i18n>
{
  "ko": {
    "editor": {
      "inspections": "문법 검사기"
    },
    "loading": "Loading..."
  },
  "en": {
    "editor": {
      "inspections": "Inspections"
    },
    "loading": "Loading...",
    "accessDeniedModal": {
      "header": "Access Denied",
      "content": "You do not have permission to access this document. Please contact the document owner for access rights.",
      "goToDashboard": "Go to My Dashboard"
    },
    "loadingProject": "Murfy is bringing up the project. Please wait a moment."
  }
}
</i18n>
